<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">{{ tableTitle }}</h2>

    <v-card>
      <v-card-text>
        <v-toolbar rounded style="margin-bottom: 40px;" elevation="0">
          <v-toolbar-title>Filtros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="table.filtros.nome" hide-details placeholder="Nome do usuário" solo dense />
          <v-spacer></v-spacer>
          <v-btn class="mr-5" color="primary" @click="mostrarCadastro">
            Novo Usuário
          </v-btn>
          <v-btn v-if="habilitaCadastro" color="primary" :loading="loadingPesquisar" @click="pesquisar">
            Pesquisar
          </v-btn>
        </v-toolbar>

        <v-card elevation="1">
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical />
            <v-spacer />
          </v-toolbar>
          <v-data-table :headers="table.headers" :items="table.items" :options.sync="table.options"
            :server-items-length="table.totalItems" :loading="loading" class="elevation-1" loading-text="Carregando..."
            dense>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td>
                    <avatar :avatarUrl="item.fotoUrl" />
                  </td>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.email }}</td>
                  <td class="text-right">
                    <v-switch class="ma-0 pa-0" v-model="item.isBloqueado" inset @change="blockUser(item)"
                      hide-details></v-switch>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogCriarUsuario" @click:outside="dialogCriarUsuario = false" scrollable max-width="350px"
      transition="dialog-transition">
      <v-card>
        <v-card-title> Criar Novo Usuário </v-card-title>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12" class="pb-0 pt-0">
                        <span class="subtitle-2">Hashtags</span>
                      </v-col> -->
            <v-col cols="12" class="col-no-padding">
              <v-text-field v-model="usuarioNome" name="Nome" placeholder="Nome do Usuário "
                class="col-no-padding" />
              <v-text-field v-model="usuarioEmail" name="Email" placeholder="Email do Usuário "
                class="col-no-padding" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small @click="dialogCriarUsuario = false"> cancelar </v-btn>
          <v-btn small color="primary" @click="novoUsuario()"> confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { UsuarioExternoService } from "@/services";
import Avatar from "@/components/avatar.vue";

export default {
  components: { Avatar },
  data: () => ({
    table: {
      items: [],
      filtros: {
        nome: "",
        email: "",
        roleId: null
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      headers: [
        { text: "Avatar", value: "fotoUrl" },
        { text: "Nome", value: "nome" },
        { text: "Email", value: "email" },
        { text: "Bloqueado", value: "" }
      ]
    },
    loadingUsuarioExterno: false,
    dialogCriarUsuario: false,
    usuarioEmail: "",
    usuarioNome: "",

    dialog: false,

    loadingSalvar: false,
    loading: false,
    loadingPesquisar: false
  }),
  watch: {
    tableOptions: {
      handler() {
        this.paginar();
      },
      deep: true
    }
  },
  computed: {
    habilitaCadastro() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaCadastroUsuarioExterno;
    },
    tableTitle() {
      return "Usuários externos";
    },
    tableOptions() {
      return this.table.options;
    }
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      this.paginar();
    },

    closeDialog() {
      this.dialog = false;
    },

    salvar() {
      this.closeDialog();
      this.reload();
    },

    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },

    mostrarCadastro() {
      this.dialogCriarUsuario = true;
    },
    novoUsuario() {
      let payload = {
        nome: this.usuarioNome,
        email: this.usuarioEmail
      }

        UsuarioExternoService.newUsuarioVinculado(payload)
          .then(() => {
            this.$root.showSucesso("Usuário cadastrado com sucesso!");
            this.dialogCriarUsuario = false;
          })
          .catch(() => {
            this.$root.showError("Falha ao tentar cadastrar novo usuário!");
          });
    },

    blockUser(item) {
      if (item.isBloqueado) {
        UsuarioExternoService.bloquear(item.id)
          .then(() => {
            this.$root.showSucesso("Usuário bloqueado com sucesso!");
          })
          .catch(() => {
            this.$root.showError("Falha ao tentar bloquear o usuário!");
          });
      } else {
        UsuarioExternoService.desbloquear(item.id)
          .then(() => {
            this.$root.showSucesso("Usuário desbloqueado com sucesso!");
          })
          .catch(() => {
            this.$root.showError("Falha ao tentar desbloquear o usuário!");
          });
      }
    },

    paginar() {
      this.loading = true;
      const { page, itemsPerPage } = this.table.options;

      UsuarioExternoService.pagination(
        itemsPerPage,
        page ? page - 1 : 0,
        "nome",
        this.table.filtros
      )
        .then(response => {
          this.table.items = response.data.content;
          this.table.totalItems = response.data.totalElements;
        })
        .finally(() => {
          this.loadingPesquisar = false;
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
